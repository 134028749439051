import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import HeaderRightTwo from "../Header-Right/HeaderRight-Two";
import Nav from "../Nav";
import flag from "../../../public/images/logo/flag-05.png";
import useTranslation from 'next-translate/useTranslation';
import LanguageSwitcher from "@/components/LanguageSwitcher";
 
const HeaderEight = ({ headerType, gapSpaceBetween, sticky, headerSticky, container }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [logoSize, setLogoSize] = useState("200px");

  const router = useRouter();
  const { locale } = router; // Get the current locale
  const { t } = useTranslation('common'); // Use translations from next-translate

  // Select the logo based on the locale
  const logoSrc = locale === 'tj'
    ? "/images/logo/logo-tj.png"
    : locale === 'ru'
      ? "/images/logo/logo-ru.png"
      : "/images/logo/logo-en.png";

  const updatePadding = () => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      if (width > 1024) return "0px 10vw";
      if (width > 768) return "0px 5vw";
      return "0px 2vw";
    }
    return "0px 2vw";
  };

  const [padding, setPadding] = useState(updatePadding());

  useEffect(() => {
    const updatePaddingAndNav = () => {
      if (typeof window !== 'undefined') {
        const width = window.innerWidth;
        if (width > 1440) {
          setLogoSize("300px");
          setPadding("0px 10vw");
        } else if (width > 1024) {
          setLogoSize("250px");
          setPadding("0px 10vw");
        } else if (width > 768) {
          setLogoSize("200px");
          setPadding("0px 5vw");
        } else {
          setLogoSize("150px");
          setPadding("0px 2vw");
        }
        setShowNav(width >= 800);
      }
    };

    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        const scrollTop = window.scrollY;
        setIsSticky(scrollTop > 300);
      }
    };

    updatePaddingAndNav();
    window.addEventListener("resize", updatePaddingAndNav);
    window.addEventListener("scroll", handleScroll);

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener("resize", updatePaddingAndNav);
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Handler to toggle search box
  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <div
      className={`rbt-header-wrapper ${gapSpaceBetween} ${sticky} ${!headerType && isSticky ? `${headerSticky}` : ""}`}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px",
        margin: '0px',
        left: '0px',
        backgroundImage: `url(${flag.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "rgba(41, 59, 142, 1)",
        backgroundBlendMode: "overlay",
      }}
    >
      <style jsx>{`
        @media (min-width: 1200px) {
          .hamburger {
            display: none;
          }
        }
      `}</style>

      <div className={`${container}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: padding,
            backgroundColor: "rgba(41, 59, 142, 0)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            <LanguageSwitcher />

            <div className="hamburger">
              <HeaderRightTwo
                userType="Mobile"
                btnText="Menu"
                btnClass="rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none"
              />
            </div>
          </div>

          {/* Logo Section */}
          {!isSticky && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0px 0px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "auto",
                backgroundColor: "rgba(41, 59, 142, 0)",
                paddingBottom: '15px'
              }}
            >
              <div className="logo" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Link href="/">
                  <Image
                    src={logoSrc}
                    priority={true}
                    alt="Localized Logo"
                    width={1000}
                    height={1000}
                    style={{
                      width: "500vw",
                      height: "auto",
                      maxHeight: "13vh",
                      objectFit: "contain",
                      transition: "width 0.3s ease-in-out",
                    }}
                  />
                </Link>
              </div>
            </div>
          )}
        </div>

        <div
          className="header-bottom-section"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100vw",
            position: isSticky ? "fixed" : "relative",
            top: isSticky ? 0 : "auto",
            backgroundColor: "white",
            zIndex: 1000,
            transition: "top 0.3s",
            paddingLeft: "0px",
            marginLeft: '-6px',
          }}
        >
          {showNav && <Nav />}
        </div>
      </div>
    </div>
  );
};

export default HeaderEight;
