import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";
import CopyRight from "./CopyRight";

// import useTranslation from 'next-translate/useTranslation';
import LanguageSwitcher from "@/components/LanguageSwitcher";


const FooterThree = () => {
  const [logoSize, setLogoSize] = useState(200); // Set initial logo size as a number
  const [showNav, setShowNav] = useState(true);
  const { t, i18n } = useTranslation("common");

  const router = useRouter();
  const { locale } = router; // Get the current locale


  // Dynamically select the logo path based on the language
  // const logoPath = `/images/logo/footer/logo-${i18n.language}.png`;
  const logoPath = locale === 'tj'
    ? "/images/logo/footer/logo-tj.png"
    : locale === 'ru'
      ? "/images/logo/footer/logo-ru.png"
      : "/images/logo/footer/logo-en.png";



  const updatePadding = () => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      if (width > 1024) return "0px 10vw";
      if (width > 768) return "0px 5vw";
      return "0px 2vw";
    }
    return "0px 2vw";
  };

  const [padding, setPadding] = useState(updatePadding());

  useEffect(() => {
    const updatePaddingAndNav = () => {
      if (typeof window !== 'undefined') {
        const width = window.innerWidth;
        if (width > 1440) {
          setLogoSize(300); // Use number without px units
          setPadding("0px 10vw");
        } else if (width > 1024) {
          setLogoSize(250);
          setPadding("0px 10vw");
        } else if (width > 768) {
          setLogoSize(200);
          setPadding("0px 5vw");
        } else {
          setLogoSize(150);
          setPadding("0px 2vw");
        }
        setShowNav(width >= 800);
      }
    };

    updatePaddingAndNav();
    window.addEventListener("resize", updatePaddingAndNav);

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener("resize", updatePaddingAndNav);
      }
    };
  }, []);

  return (
    <>
      <footer className="rbt-footer footer-style-1" style={{ paddingLeft: "25px" }}>
        <div className="footer-top">
          <div className="container">
            {FooterData && FooterData.footerOne.map((footer, index) => (
              <div className="row row--15 mt_dec--30" key={index}>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} className="footer-widget">
                    <div className="logo" style={{ flexGrow: 1 }}>
                    <Link href="/">
                      <Image
                        src={logoPath}
                        alt="Mjdri Logo"
                        width={logoSize}
                        height={logoSize * 0.5}
                        priority={true}
                        style={{
                          maxHeight: "100px",
                          objectFit: "contain",
                          transition: "width 0.3s ease-in-out",
                        }}
                      />
                    </Link>

                    </div>
                    <div
                      className="contact-btn mt--30"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Link
                        className="rbt-btn hover-icon-reverse radius-round"
                        href="/tamos-va-qabul/tamos"
                      >
                        <div className="icon-reverse-wrapper">
                          <span className="btn-text"> {t('footer.message')} </span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <SingleFooter
                  classOne="offset-lg-1 col-lg-2 col-md-6 col-sm-6 col-12 mt--30"
                  title={t("footer.news")}
                  footerType={footer.usefulLinks.map(link => ({
                    ...link,
                    text: link.text[i18n.language] || link.text["en"]
                  }))}
                />

                <SingleFooter
                  classOne="col-lg-2 col-md-6 col-sm-6 col-12 mt--30"
                  title={t("footer.center")}
                  footerType={footer.ourCompany.map(link => ({
                    ...link,
                    text: link.text[i18n.language] || link.text["en"]
                  }))}
                />

                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
                  <div className="footer-widget">
                    <h5 className="ft-title"> {t("footer.contact")} </h5>
                    <ul className="ft-link">
                      <li>
                        <span> {t("footer.phone")} </span>
                        <Link href="#">{footer.phone}</Link>
                      </li>
                      <li>
                        <span>E-mail: </span>
                        <Link href="mailto:mjdri@mail.ru">{footer.mail}</Link>
                      </li>
                      <li>
                        <span> {t("footer.address")} </span> {footer.address[i18n.language] || footer.address["en"]}
                      </li>
                    </ul>
                    <ul className="social-icon social-default icon-naked justify-content-start mt--20">
                      {footer.socialLink.map((value, innerIndex) => (
                        <li key={innerIndex}>
                          <Link target="_blank" href={value.link} >
                            <i className={value.icon}></i>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </footer>
      <CopyRight />
    </>
  );
};



export default FooterThree;
